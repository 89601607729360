import React, { useState } from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {

    const [current, setCurrent] = useState(currentPage);

    const handlePageChange = (page) => {
        setCurrent(page);
        onPageChange(page);
    };

    const handleNext = () => {
        if (current < totalPages) {
            handlePageChange(current + 1);
        }
    };

    const handlePrevious = () => {
        if (current > 1) {
            handlePageChange(current - 1);
        }
    };

    const getPages = () => {

        const pages = [];

        // Always show the first 5 pages
        const firstFive = [1, 2, 3, 4, 5];

        // Always show the last 5 pages
        const lastFive = [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];

        // Calculate middle pages around the current page
        const middleStart = Math.max(current - 2, 6); // Start from current - 2 but ensure it starts after the first five pages
        const middleEnd = Math.min(current + 2, totalPages - 5); // End at current + 2 but ensure it ends before the last five pages

        if (totalPages > 5) {
            // Always show the first 5 pages
            firstFive.forEach((page) => {
                pages.push(page);
            });
        } else {
            for (var i = 0; i < totalPages; i++) {
                pages.push(i + 1);
            }
        }

        // Add an ellipsis if there is a gap between first 5 and middle pages
        if (middleStart > 6) {
            pages.push("...");
        }

        // Show the middle 5 pages
        for (let i = middleStart; i <= middleEnd; i++) {
            pages.push(i);
        }

        // Add an ellipsis if there is a gap between middle and last 5 pages
        if (middleEnd < totalPages - 5) {
            pages.push("...");
        }

        if (totalPages > 5) {
            // Always show the last 5 pages
            lastFive.forEach((page) => {
                pages.push(page);
            });
        }
        return pages;
    };

    return (
        <div className="d-flex justify-content-center pagination">
            {/* First Button */}
            <button
                onClick={() => handlePageChange(1)}
                className="page-button"
                disabled={current === 1}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    style={{ width: '16px', height: '16px' }}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 5l-7 7 7 7M11 5l-7 7 7 7" />
                </svg>
            </button>

            {/* Previous Button */}
            <button
                onClick={handlePrevious}
                className="page-button"
                disabled={current === 1}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    style={{ width: '16px', height: '16px' }}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
            </button>

            {/* Page Numbers */}
            {getPages().map((page, index) => (
                <button
                    key={index}
                    onClick={() => typeof page === "number" && handlePageChange(page)}
                    className={`page-button ${current === page ? "page-button-active" : ""}`}
                    disabled={page === "..." || current === page}
                    style={page === "..." ? { cursor: 'default', color: 'gray' } : {}}
                >
                    {page}
                </button>
            ))}

            {/* Next Button */}
            <button
                onClick={handleNext}
                className="page-button"
                disabled={current === totalPages}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    style={{ width: '16px', height: '16px' }}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                </svg>
            </button>

            {/* Last Button */}
            <button
                onClick={() => handlePageChange(totalPages)}
                className="page-button"
                disabled={current === totalPages}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    style={{ width: '16px', height: '16px' }}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 5l7 7-7 7M13 5l7 7-7 7" />
                </svg>
            </button>
        </div>
    );
};

export default Pagination;
