import { instAxios } from "./helper"
import authHeader from "./auth-header";

const register = (username, email, password) => {
    return instAxios.post("signup", {
        username,
        email,
        password,
    });
};

const login = async (username, password) => {
    const response = await instAxios.post("auth/signin", {
        username,
        password,
    });
    if (response.data.accessToken) {
        // console.log(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};

const changePassword = async (username, password) => {
    const response = await instAxios.put("/user/changepassword", {
        username,
        password,
    }, { headers: authHeader() });
    if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    changePassword
};

export default AuthService;