import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { getMenuObject } from '../services/helper';
import gs from '../services/gs';

const Footer = () => {
    const { t } = useTranslation();

    const [data, setData] = useState();
    const [menu, setMenu] = useState();

    useEffect(() => {
        gs.getByType('menu', 58).then(res => {
            setData(res);
        });
    }, []);

    useEffect(() => {
        if (data !== undefined) {
            setMenu(getMenuObject(data));
        }
    }, [data]);

    return (
        <div className="footer">
            <div className="container">
                <div className="flex-container">
                    {menu && menu.map((eachMenu) => {
                        return (
                            <div key={eachMenu.id} className="felx-child-25">
                                <h4>{t(eachMenu.name)}</h4>
                                {
                                    eachMenu.submenu && eachMenu.submenu.map((submenu) => {
                                        return <Link className="footer-link" to={submenu.url} key={submenu.id}>{t(submenu.name)}</Link>
                                    })
                                }
                            </div>
                        )
                    })}

                    <div className="felx-child-25">
                        <h4>{t('contact')}</h4>
                        <p>
                            {t('gulbarga-university')}<br />
                            {t('jnana-ganga')},<br />
                            {t('kalaburagi')} - 585 106,<br />
                            {t('karnataka')} {t('state')}, {t('india')}<br />
                            Tel No:+91 8472 263202<br />
                            <br />
                            {t('registrar-admin')}	+91 8472 263202<br />
                            {t('registrar-evaluation')}	+91 8472 263203<br />
                            {t('finance-officer')}	+91 8472 263204
                        </p>
                    </div>
                </div>
            </div>

            <div className="copyright">All Rights Reserved | Copyright © 2023 Gulbarga University. Powered by Appa Infotech Services Pvt. Ltd.</div>
        </div>
    );
}

export default Footer;