
// https://reactjsexample.com/tag/menu/
// https://blog.logrocket.com/how-create-multilevel-dropdown-menu-react/
// https://github.com/Ibaslogic/react-multilevel-dropdown-menu/blob/main/src/routes/Home.js

// https://github.com/nukeop/react-ui-cards/blob/master/src/UserCard/index.tsx
// https://github.com/UsmanLiaqat99/Animated-ProfileCard
// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './routes/Home';
import GoodGovernance from './routes/GoodGovernance';
import Faculty from './routes/Faculty';
import Department from './routes/Department';
import CollegeList from './routes/CollegeList';
import Syndicate from './routes/Syndicate';
import Academic from './routes/Academic';
import EmployeeDirectory from './routes/EmployeeDirectory';
import Deans from './routes/Deans';
import Upload from './components/Upload';
import ContactUs from './routes/ContactUs';
import Notifications from './routes/Notifications';
import Implink from './routes/Implink';
import Auditorium from './routes/Auditorium';
import Hostels from './routes/Hostels';
import Stadium from './routes/Stadium';
import Bank from './routes/Bank';
import PostOffice from './routes/PostOffice';
import StaffQuarters from './routes/StaffQuarters';
import SWO from './routes/SWO';
import IQAC from './routes/IQAC';
import Alumni from './routes/Alumni';
import RTI from './routes/RTI';
import StatutoryOfficers from './routes/StatutoryOfficers';
import UgPrograms from './routes/UgPrograms';
import PhdPrograms from './routes/PhdPrograms';
import PgCentres from './routes/PgCentres';
import Regulations from './routes/Regulations';
import StudyChairs from './routes/StudyChairs';
import Syllabus from './routes/Syllabus';
import Prospectus from './routes/Prospectus';
import CalendarEvents from './routes/CalendarEvents';
import FeesStructure from './routes/FeesStructure';
import USIC from './routes/USIC';
import GuestHouse from './routes/GuestHouse';
import HealthCenter from './routes/HealthCenter';
import Sports from './routes/Sports';
import NSS from './routes/NSS';
import LibraryHome from './routes/library/LibraryHome';
import LibraryLayout from './routes/library/LibraryLayout';
import Collections from './routes/library/Collections';
import UsefulLinks from './routes/library/UsefulLinks';
import ICTFS from './routes/library/ICTFS';
import AwardsCredentials from './routes/library/AwardsCredentials';
import Competitive from './routes/library/Competitive';
import LibraryBulletin from './routes/library/LibraryBulletin';
import Training from './routes/library/Training';
import LibraryStaff from './routes/library/LibraryStaff';
import Suggestions from './routes/library/Suggestions';
import ProjectsMou from './routes/library/ProjectsMou';
import LGP from './routes/library/LGP';
import DeptLibraries from './routes/library/DeptLibraries';
import AdminHome from './routes/admin/AdminHome';
import AdminLayout from './routes/admin/AdminLayout';
import AdminListView from './routes/admin/AdminListView';
import AdminFormView from './routes/admin/AdminFormView';
import LibrarianMsg from './routes/library/LibrarianMsg';
import NewsNotiLib from './routes/library/NewsNotiLib';
import TextEditor from './routes/admin/TextEditor';
import Page from './routes/Page';
import Login from './routes/admin/Login';
import ChangePassword from './routes/admin/ChangePassword';
import NEP from './routes/NEP';
import NEPSyllabus from './routes/NEPSyllabus';
import Admission from './routes/Admission';
import UUCMS from './routes/UUCMS';
import OldAnnouncements from './routes/OldAnnouncements';
import HelpDesk from './routes/library/HelpDesk';
import EResources from './routes/library/EResources';

function App() {
  return (
    <div>
      <ToastContainer position='bottom-center' />
      <Routes>
        <Route path="/admin" element={<AdminLayout />} >
          <Route index element={<AdminHome />} />
          <Route path="list/:tableId" element={<AdminListView />} />
          <Route path="form/:table" element={<AdminFormView />} />
          <Route path="form/:table/:Id" element={<AdminFormView />} />
          <Route path="upload" element={<Upload />} />
          <Route path="text-editor" element={<TextEditor />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/library" element={<LibraryLayout />}>
          <Route index element={<LibraryHome />} />
          <Route path="librarian-msg" element={<LibrarianMsg />} />
          <Route path="collections" element={<Collections />} />
          <Route path="useful-links" element={<UsefulLinks />} />
          <Route path="ictfs" element={<ICTFS />} />
          <Route path="awards-credentials" element={<AwardsCredentials />} />
          <Route path="competitive-centre" element={<Competitive />} />
          <Route path="library-bulletin" element={<LibraryBulletin />} />
          <Route path="training-development" element={<Training />} />
          <Route path="library-staff" element={<LibraryStaff />} />
          <Route path="suggestions" element={<Suggestions />} />
          <Route path="projects-mou" element={<ProjectsMou />} />
          <Route path="governance-policies" element={<LGP />} />
          <Route path="dept-libraries" element={<DeptLibraries />} />
          <Route path="news-notification" element={<NewsNotiLib />} />
          <Route path="help-desk" element={<HelpDesk />} />
          <Route path="lib-e-resources" element={<EResources />} />
          <Route path="page/:pageId" element={<Page />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="login" element={<Login />} />

          <Route path="iqac" element={<IQAC />} />
          <Route path="alumni" element={<Alumni />} />
          <Route path="good-governance" element={<GoodGovernance />} />
          <Route path="rti" element={<RTI />} />

          {/* Highlights */}
          <Route path="nep" element={<NEP />} />
          <Route path="nep/:nepId" element={<NEPSyllabus />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="admissions" element={<Admission />} />
          <Route path="uucms" element={<UUCMS />} />
          <Route path="old-announcements" element={<OldAnnouncements />} />

          {/* ABOUT */}
          <Route path="page/:pageId" element={<Page />} />

          {/* ADMINISTRATION */}
          <Route path="statutory-officers" element={<StatutoryOfficers />} />
          <Route path="deans" element={<Deans />} />
          <Route path="syndicate" element={<Syndicate />} />
          <Route path="academic-council" element={<Academic />} />

          {/* ACADEMICS */}
          <Route path="ug-programs" element={<UgPrograms />} />
          <Route path="faculty/:facultyId" element={<Faculty />} />
          <Route path="faculty/:facultyId/dept/:deptId" element={<Department />} />
          <Route path="phd-programs" element={<PhdPrograms />} />
          <Route path="pg-centres" element={<PgCentres />} />
          <Route path="regulations" element={<Regulations />} />
          <Route path="study-chairs" element={<StudyChairs />} />
          <Route path="syllabus" element={<Syllabus />} />
          <Route path="prospectus" element={<Prospectus />} />
          <Route path="calendar-events" element={<CalendarEvents />} />
          <Route path="fee-structure" element={<FeesStructure />} />

          {/* CENTRAL FACILITIES */}
          <Route path="usic" element={<USIC />} />
          <Route path="guest-house" element={<GuestHouse />} />
          <Route path="health-centre" element={<HealthCenter />} />
          <Route path="sports-gym" element={<Sports />} />
          <Route path="hostels" element={<Hostels />} />
          <Route path="nss" element={<NSS />} />

          {/* FOOTER */}
          {/* FACILITIES */}
          <Route path="auditorium" element={<Auditorium />} />

          <Route path="stadium" element={<Stadium />} />
          <Route path="bank" element={<Bank />} />
          <Route path="post-office" element={<PostOffice />} />
          <Route path="staff-quarters" element={<StaffQuarters />} />
          <Route path="swo" element={<SWO />} />

          {/* DIRECTORIES */}
          <Route path="employee-directory" element={<EmployeeDirectory />} />

          {/* ...*/}
          <Route path="affiliated-colleges" element={<CollegeList />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="implink" element={<Implink />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
